import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    orgType: "",
    generalInfo: null,
    notification: false,
    // Бүлэг
    // teacherGroups: [],
    // teacherGroup: undefined,

    // // Ээлжит хичээл
    // teacherClasses: [],
    // teacherClass: undefined,
  },
  reducers: {
    changeOrgType: {
      reducer: (state, action) => {
        state.orgType = action.payload;
      },
      prepare: (type) => {
        return {
          payload: type,
        };
      },
    },
    changeGeneralInfo: {
      reducer: (state, action) => {
        state.generalInfo = action.payload;
      },
      prepare: (info) => {
        return {
          payload: info,
        };
      },
    },
    changeNotification: {
      reducer: (state, action) => {
        state.notification = !state.notification;
      },
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(getTeacherGroupsList.fulfilled, (state, action) => {
    //   if (action.payload.length > 0) {
    //     state.teacherGroups = action.payload;
    //     state.teacherGroup = action.payload[0];
    //   }
    // });
    // builder.addCase(getTeacherGroupsList.rejected, (state, action) => {
    //   state.teacherGroups = [];
    //   state.teacherGroup = undefined;
    // });
    // builder.addCase(getClassesByInstructorId.fulfilled, (state, action) => {
    //   if (action.payload.length > 0) {
    //     state.teacherClasses = action.payload;
    //     state.teacherClass = action.payload[0];
    //   }
    // });
  },
});

// const getTeacherGroupsList = createAsyncThunk(
//   "app/getTeacherGroups",
//   async (params, { rejectWithValue }) => {
//     const { personId, institutionId } = localStorage;
//     return await apiGet(`teacher/groups/list/${personId}/${institutionId}`);
//   }
// );

// const getSessionList = createAsyncThunk(
//   "app/getSessionList",
//   async (params, { rejectWithValue }) => {
//     const { personId, institutionId } = localStorage;
//     return await apiGet(`teacher/groups/list/${personId}/${institutionId}`);
//   }
// );

// // Ээлжит хичээл
// const getClassesByInstructorId = createAsyncThunk(
//   "app/getSessionList",
//   async (params, { rejectWithValue }) => {
//     const { personId, institutionId } = localStorage;
//     return await apiGet(
//       `classes/listByInstructorId/${personId}/${institutionId}`
//     );
//   }
// );

// export { getTeacherGroupsList };

export const { changeOrgType, changeGeneralInfo, changeNotification } =
  appSlice.actions;
export default appSlice.reducer;
