import { configureStore } from "@reduxjs/toolkit";
import loader from "../components/base/Loader/LoaderSlice";
import journalSlice from "../pages/Journal/journalSlice";
import springWorkSlice from "../pages/SpringWork/springWorkSlice";
import studentSlice from "../pages/Class/Student/studentSlice";
import testReduxPage from "../pages/TestReduxPage/testReduxPageSlice";
import parentSlice from "../pages/Parent/parentSlice";
import app from "./appSlice";
import classSlice from "../pages/Class/classSlice";

const store = configureStore({
  reducer: {
    loader: loader,
    testReduxPage: testReduxPage,
    app: app,
    journal: journalSlice,
    springWork: springWorkSlice,
    student: studentSlice,
    parent: parentSlice,
    class: classSlice,
  },
});

export default store;
