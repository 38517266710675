import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeGeneralInfo,
  changeNotification,
  changeOrgType,
} from "../store/appSlice";

const useAppSlice = () => {
  const dispatch = useDispatch();

  const orgType = useSelector((state) => state.app.orgType);
  const setOrgType = React.useCallback(
    (type) => dispatch(changeOrgType(type)),
    [dispatch]
  );

  const generalInfo = useSelector((state) => JSON.parse(state.app.generalInfo));
  // console.log("generalInfo", generalInfo);
  const setGeneralInfo = React.useCallback(
    (info) => dispatch(changeGeneralInfo(info)),
    [dispatch]
  );

  const notification = useSelector((state) => state.app.notification);
  const refreshNotifications = () => dispatch(changeNotification());
  // Хичээл ордог бүлгүүд
  // const teacherGroups = useSelector((state) => state.app.teacherGroups);
  // const teacherGroup = useSelector((state) => state.app.teacherGroup);

  // Ээлжит хичээл
  // const teacherClasses = useSelector((state) => state.app.teacherClasses);
  // const teacherClass = useSelector((state) => state.app.teacherClass);

  // Хичээл ордог бүлгүүдийг буцаана.
  // const getTeacherGroups = () => dispatch(getTeacherGroupsList());

  useEffect(() => {
    if (!orgType) {
      setOrgType(localStorage.getItem("orgType"));
    }
  }, [orgType, setOrgType]);

  useEffect(() => {
    if (!generalInfo) {
      setGeneralInfo(localStorage.getItem("generalInfo"));
    }
  }, [generalInfo, setGeneralInfo]);

  return {
    orgType,
    setOrgType,
    generalInfo,
    setGeneralInfo,
    notification,
    refreshNotifications,
  };
};

export default useAppSlice;
