import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet } from "../../utils/helper";

const testReduxPageSlice = createSlice({
  name: "testReduxPage",
  initialState: {
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInstitution.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getInstitution.rejected, (state, action) => {
      state.data = [];
    });
  },
});

const getInstitution = createAsyncThunk(
  "testReduxPage/getInstitution",
  async (params, { rejectWithValue }) => {
    return await apiGet(`common/generalInfo/${params.insId}/${params.date}`);
  }
);

export { getInstitution };
export default testReduxPageSlice.reducer;
