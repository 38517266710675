import "./App.scss";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Loader from "./components/base/Loader/Loader";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import base64 from "base-64";
import { call, treeList } from "./utils/helper";
import { isNull } from "lodash";
import jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";
import axios from "axios";
import { BASE_URL } from "./utils/config";
import TestReduxPage from "./pages/TestReduxPage";
import useAppSlice from "./hooks/useAppSlice";

const Manual = lazy(() => import("./pages/Manual"));
const JournalSummary = lazy(() =>
  import("./pages/Journal/Report/JournalSummary")
);
const Homework = lazy(() => import("./pages/Homework"));
const HomeworkAssesment = lazy(() => import("./pages/Journal/Homework"));
const Beside = lazy(() => import("./pages/Class/ClassNote/Beside"));
const ClassReport = lazy(() => import("./pages/Class/ClassReport"));
const CourseMembers = lazy(() => import("./pages/Lesson/CourseMembers"));
const StudentNote = lazy(() => import("./pages/Class/ClassNote/StudentNote"));
const TeacherSummary = lazy(() =>
  import("./pages/Class/ClassNote/TeacherSummary")
);
const ClassActivity = lazy(() =>
  import("./pages/Class/ClassNote/ClassActivity")
);
const ClassNote = lazy(() => import("./pages/Class/ClassNote"));
const Class = lazy(() => import("./pages/Class"));
const Journal = lazy(() => import("./pages/Journal"));
const TeacherProfile = lazy(() =>
  import("./pages/Teacher/Profile/TeacherProfile")
);
const ClassMembers = lazy(() =>
  import("./pages/Lesson/ClassMembers/ClassMembers")
);
const Note = lazy(() => import("./pages/Journal/Note"));
const ReportStudentEvaluvationSum = lazy(() =>
  import("./pages/Journal/Report/CustomReport/ReportStudentEvaluvationSum")
);
const ReportStudentEvaluvationPage = lazy(() =>
  import("./pages/Journal/Report/CustomReport/ReportStudentEvaluvationPage")
);
const ReportCourseEvaluationSum = lazy(() =>
  import("./pages/Journal/Report/CustomReport/ReportCourseEvaluationSum")
);
const ReportEvaluation = lazy(() =>
  import("./pages/Journal/Report/CustomReport/ReportEvaluation_1_3")
);
const ReportEvaluationSum = lazy(() =>
  import("./pages/Journal/Report/CustomReport/ReportEvaluationSum_3_12")
);
const ReportTsahim = lazy(() =>
  import("./pages/Journal/Report/CustomReport/ReportTsahim")
);
const Course = lazy(() =>
  import("./pages/Journal/Report/CustomReport/ReportEvaluation")
);
const CookJournal = lazy(() => import("./pages/Cook/CookJournal"));
const Login = lazy(() => import("./pages/Login/Login"));
const IForm = lazy(() => import("./pages/IForm/IForm.jsx"));
const ParentRequest = lazy(() =>
  import("./pages/Parent/ParentRequest/ParentRequest")
);
const Home = lazy(() => import("./pages/Home/Home"));
const Student = lazy(() => import("./pages/Class/Student/Student"));
const Profile = lazy(() => import("./pages/Class/Profile/profile"));
const Attendance = lazy(() => import("./pages/Class/Attendance/index"));
const ClassList = lazy(() => import("./pages/Lesson/ClassList/ClassList"));
const AbsenceManagement = lazy(() =>
  import("./pages/Parent/AbsenceManagement")
);
const Graduation = lazy(() =>
  import("./pages/SpringWork/Graduation/Graduation")
);
const AttendanceNote = lazy(() =>
  import("./pages/Journal/AttendanceNote/AttendanceNote")
);
const Page404 = lazy(() => import("./pages/Page404"));
const JournalDashboard = lazy(() => import("./pages/Journal/JournalDashboard"));
const ScheduleGroup = lazy(() => import("./pages/Class/Schedule"));
const Unelgee2 = lazy(() => import("./pages/Journal/Unelgee2"));
const Unelgee1 = lazy(() => import("./pages/Journal/Unelgee1"));
const Exercise = lazy(() => import("./pages/Journal/Exercise"));
const Exam = lazy(() => import("./pages/SpringWork/Exam"));
const GroupTopic = lazy(() => import("./pages/Lesson/GroupTopics/GroupTopic"));
const GroupTopics = lazy(() => import("./pages/Lesson/GroupTopics"));
const Perform = lazy(() => import("./pages/Performance"));
const BaseComponents = lazy(() =>
  import("./pages/BaseComponents/BaseComponents")
);
const Progression = lazy(() =>
  import("./pages/SpringWork/Progression/Progression")
);
const MainLayout = lazy(() => import("./layout/MainLayout/MainLayout"));
const SubProgression = lazy(() =>
  import("./pages/SpringWork/SubProgression/SubProgression")
);
//Багшийн журнал тайлан
const TeacherReport = lazy(() => import("./pages/Journal/Report/index"));
const TeacherJournalReport = lazy(() =>
  import("./pages/Journal/Report/TeacherJournalReport")
);
const CustomReport = lazy(() => import("./pages/Journal/Report/CustomReport"));
const SocialJournalTab = lazy(() => import("./pages/SocialWorker"));
const SocialAttendance = lazy(() => import("./pages/SocialWorker/Attendance"));
const SocialJournal = lazy(() =>
  import("./pages/SocialWorker/SocialJournal/index")
);
const SocialReport = lazy(() =>
  import("./pages/SocialWorker/SocialReport/index")
);

const PasswordRecovery1 = lazy(() => import("./pages/Login/passChangeStep1"));
const PasswordRecovery2 = lazy(() => import("./pages/Login/passChangeStep2"));

function App() {
  let temp = localStorage.getItem("temp");
  const hash = window.location.hash.substring(5);
  const new_patch = window.location.hash.substring(1, 3);

  const [state, setState] = useState({
    logged: temp ? true : false,
  });

  const { setOrgType } = useAppSlice();

  // const navigate = useNavigate();

  let basename = process.env.PUBLIC_URL;
  // console.log(basename);
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    //adf -с дуудах
    const cToken = urlParams.get("cToken");
    if (cToken) {
      const { temp } = localStorage;
      let decodedObj = JSON.parse(base64.decode(cToken));
      localStorage.setItem("temp", decodedObj?.token);
      localStorage.setItem("institutionId", decodedObj?.institutionId);
      localStorage.setItem("academicYear", decodedObj?.academicYear);

      if (!temp || decodedObj?.token !== temp) {
        window.location.reload();
      }
    }

    const tk = urlParams.get("tk");

    var token = tk;
    if (!isNull(tk) && !localStorage.getItem("orgType")) {
      var decoded = jwt_decode(token);
      localStorage.setItem("temp", tk);
      localStorage.setItem(
        "institutionId",
        decoded.sessionObject.institutionId
      );
      localStorage.setItem("orgType", decoded.sessionObject.orgType);

      // return window.location.reload();
    }
    if (new_patch === "/ge") {
      clearListCookies();
      window.location.hash = "";
      if (hash === "") {
        return (window.location.href =
          process.env.REACT_APP_HOME_URL + "error");
      }
      let bytes = CryptoJS.AES.decrypt(hash, "asd4f65a4s65d4f6a54sd6f54a6s54d");
      let RESULT = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      axios
        .post(BASE_URL + "/login", RESULT, {
          withCredentials: true,
        })
        .then((resLogin) => {
          console.log(resLogin);
          if (resLogin.data.SUCCESS_CODE === 200) {
            // setLogged(resLogin.data.RESULT);
            const data = resLogin.data.RESULT;
            if (
              data.groupName === "teacher" ||
              // data.groupName === "cookManager" ||
              // data.groupName === "cooker" ||
              data.groupName === "watchman"
            ) {
              temp = true;
              call(
                "get",
                "/menu/" +
                  data.groupName +
                  "/" +
                  data.orgType +
                  "/" +
                  data.journalStatus,
                " ",
                data.token
              )
                .then((res) => {
                  if (res.status === 200) {
                    const menu = res.data.RESULT;
                    localStorage.setItem(
                      "menus",
                      JSON.stringify(treeList(menu, 0))
                    );
                    setLogged(data);
                  }
                  if (res.status === 201) {
                    //  setData(res.data.RESULT);
                  }
                })
                .catch(() => {
                  // setPending(false);
                });
            } else {
              localStorage.clear();
              return (window.location.href = process.env.REACT_APP_HOME_URL);
            }
          } else {
            // deleteAllCookies();
            localStorage.clear();
            return (window.location.href = process.env.REACT_APP_HOME_URL);
          }
        })
        .catch(() => {
          return (window.location.href = process.env.REACT_APP_HOME_URL);
        });
    }
  }, []);
  let setLogged = (RESULT) => {
    console.log(RESULT);
    // alert(JSON.stringify(RESULT));
    localStorage.setItem("temp", RESULT.token);
    localStorage.setItem("personId", RESULT.personId);
    localStorage.setItem("institutionId", RESULT.institutionId);
    localStorage.setItem("academicYear", RESULT.academicYear);
    localStorage.setItem("displayName", RESULT.displayName);
    localStorage.setItem("username", RESULT.userName);
    localStorage.setItem("orgType", RESULT.orgType);
    localStorage.setItem("groupName", RESULT.groupName);
    localStorage.setItem("journalStatus", RESULT.journalStatus);
    localStorage.setItem("studentGroups", JSON.stringify(RESULT.studentGroups));

    setOrgType(RESULT.orgType);
    setState({ ...state, logged: true });

    if (RESULT.groupName === "watchman") {
      window.location.href = `${basename}${"/socialworker/socialattendance"}`;
    } else
      window.location.href = `${basename}${
        RESULT.orgType === "SUB" ? "/class/journalClass/attendance" : "/home"
      }`;

    // navigate("home");
  };
  const logout = () => {
    clearListCookies();

    temp = false;

    const BASE_URLS = localStorage.BASE_URLS;

    localStorage.clear();

    localStorage.setItem("BASE_URLS", BASE_URLS);

    // toastify("WARNING", "Системээс гарлаа");
    window.location.href = basename + "/";
    window.reload();
  };
  function clearListCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var spcook = cookies[i].split("=");
      deleteCookie(spcook[0]);
    }
    function deleteCookie(cookiename) {
      var d = new Date();
      d.setDate(d.getDate() - 1);
      var expires = ";expires=" + d;
      var name = cookiename;
      var value = "";
      document.cookie = name + "=" + value + expires + "; path=/acc/html";
    }
  }
  return (
    // <LoadingProvider>
    <div className="App">
      <Suspense fallback={<Loader />}>
        <ToastContainer theme="colored" />
        <Router basename={basename}>
          <Routes>
            {state.logged ? (
              <Route
                path={basename + "/"}
                element={<MainLayout logout={logout} />}
              >
                <Route path="*" element={<Page404 />} />
                <Route exact path="/base" element={<BaseComponents />} />
                <Route path="home" element={<Home />} />
                <Route path="test-redux" element={<TestReduxPage />} />
                <Route path="/journal" element={<Journal />}>
                  <Route path="attendanceNote" element={<AttendanceNote />} />
                  <Route path="dashboard" element={<JournalDashboard />} />
                  <Route path="unelgee1" element={<Unelgee1 />} />
                  <Route path="unelgee2" element={<Unelgee2 />} />
                  <Route path="grade" element={<Note />} />
                  <Route path="hmwork" element={<HomeworkAssesment />} />
                  <Route path="exercise" element={<Exercise />} />
                  <Route path="print" element={<TeacherReport />}>
                    <Route
                      path="teacherJournal"
                      element={<TeacherJournalReport />}
                    />
                    <Route path="journalSummary" element={<JournalSummary />} />
                    <Route path="report" element={<CustomReport />}>
                      <Route path="course" element={<Course />}></Route>
                      <Route
                        path="CourseEvaluvationSum"
                        element={<ReportCourseEvaluationSum />}
                      />
                      <Route
                        path="student"
                        element={<ReportStudentEvaluvationPage />}
                      />
                      <Route
                        path="StudentEvaluvationSum"
                        element={<ReportStudentEvaluvationSum />}
                      />
                      <Route
                        path="evaluationSum"
                        element={<ReportEvaluationSum />}
                      />
                      <Route
                        path="evaluationSumElementary"
                        element={<ReportEvaluation />}
                      />
                      <Route path="ReportTsahim" element={<ReportTsahim />} />
                    </Route>
                  </Route>
                </Route>

                <Route path="class" element={<Class />}>
                  <Route path="student" element={<Student />} />
                  <Route
                    path="student/:editFlag/:personId/"
                    element={<Profile />}
                  />
                  <Route
                    path="journalClass/attendance"
                    element={<Attendance />}
                  />
                  <Route path="schedule/group" element={<ScheduleGroup />} />
                  <Route path="note" element={<ClassNote />}>
                    <Route path="student/:type" element={<StudentNote />} />
                    <Route path="activity/:type" element={<ClassActivity />} />
                    <Route path="beside" element={<Beside />} />
                    <Route path="summary" element={<TeacherSummary />} />
                  </Route>
                  <Route path="class-report" element={<ClassReport />} />
                </Route>

                <Route path="/class-list" element={<ClassList />} />
                <Route path="/classMembers/list" element={<ClassMembers />} />
                <Route path="/courseMembers" element={<CourseMembers />} />

                <Route path="/parentRequest" element={<ParentRequest />} />
                <Route path="/iform" element={<IForm />} />
                <Route path="/progression" element={<Progression />} />
                <Route path="/graduation" element={<Graduation />} />

                <Route path="/teacher/exam" element={<Exam />} />
                <Route path="/get/getOff" element={<AbsenceManagement />} />
                <Route path="/grouptopics" element={<GroupTopics />} />
                <Route path="/grouptopics/:courseId" element={<GroupTopic />} />
                <Route path="/cookJournal" element={<CookJournal />} />
                <Route path="/hwork" element={<Homework />} />
                <Route path="/perform" element={<Perform />} />
                <Route path="/manual" element={<Manual />} />

                {/* Багшийн мэдээлэл, хэтэвч */}
                <Route path="/profile" element={<TeacherProfile />} />

                <Route path="/sub/progression" element={<SubProgression />} />
                <Route path="/socialworker" element={<SocialJournalTab />}>
                  <Route
                    path="socialattendance"
                    element={<SocialAttendance />}
                  />
                  <Route path="socialjournal" element={<SocialJournal />} />
                  <Route path="socialreport" element={<SocialReport />} />
                </Route>
                {/* <Route
                  path="/print/teacherJournal"
                  element={<TeacherJournalReport />}
                >
                  <Route path="title" element={<TJTitle />} />
                </Route> */}
              </Route>
            ) : (
              <>
                <Route path="*" element={<Page404 />} />
                <Route
                  exact
                  path="/"
                  element={<Login setLogged={setLogged} />}
                />
                <Route
                  path="password/recover"
                  element={<PasswordRecovery1 />}
                />
                <Route
                  path="password/send-mail"
                  element={<PasswordRecovery2 />}
                />
                <Route
                  exact
                  path="/login"
                  element={<Login setLogged={setLogged} />}
                />
              </>
            )}
          </Routes>
        </Router>
      </Suspense>
    </div>
    // </LoadingProvider>
  );
}
export default App;
