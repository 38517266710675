import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

import { apiGet } from "../../utils/helper";
import { prepareScheduleData } from "../Home/helper";
import useJournal from "../../hooks/useJournal";

const journalSlice = createSlice({
  name: "journal",
  initialState: {
    // Ээлжит хичээл
    teacherClasses: [],
    teacherClassId: "",

    //Хичээлийн улирал
    classSessions: [],
    classSessionId: "",

    classSessionIdChanged: false,

    // Ирц багшийн тэмдэглэл
    attendanceNotes: [],

    // Нэгж хичээлийн сэдэв
    courseTopics: [],

    // Хичээлийн жилийн улирал
    sessionId: "",
    sessions: [],

    //Нүүр хуудас хичээлийн хуваарь
    schedules: [],
    activeDate: new Date().toDateString(),

    // Багшийн өмнөх тэмдэглэлүүд
    // courseTopicSuggestions: [],
  },
  reducers: {
    changeTeacherClass: {
      reducer: (state, action) => {
        // if (action.payload !== state.teacherClassId)
        state.teacherClassId = action.payload;
      },
      prepare: (classId) => {
        return {
          payload: classId,
        };
      },
    },
    changeClassSessionId: {
      reducer: (state, action) => {
        // if (action.payload !== state.classSessionId)
        state.classSessionId = action.payload;
        state.classSessionIdChanged = !state.classSessionIdChanged;
      },
      prepare: (sessionId) => {
        return {
          payload: sessionId,
        };
      },
    },

    changeSessionId: {
      reducer: (state, action) => {
        state.sessionId = action.payload;
      },
      prepare: (sessionId) => {
        return {
          payload: sessionId,
        };
      },
    },

    changeCourseTopics: {
      reducer: (state, action) => {
        state.courseTopics = action.payload;
      },
      prepare: (topics) => {
        return {
          payload: topics,
        };
      },
    },

    changeActiveDate: {
      reducer: (state, action) => {
        state.activeDate = action.payload;
      },
      prepare: (date) => {
        return {
          payload: date,
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClassesByInstructorId.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.teacherClasses = action.payload;
        state.teacherClassId = action.payload[0].classId;
        state.classSessionId = "";
        state.classSessions = [];
      }
    });
    builder.addCase(getSessionListByClassId.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.classSessions = action.payload;
        state.classSessionId = action.payload[0].sessionId;
        state.classSessionIdChanged = !state.classSessionIdChanged;
      }
    });
    builder.addCase(getAttendanceNoteList.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.attendanceNotes = action.payload;
      }
    });
    builder.addCase(getAttendanceNoteList.rejected, (state, action) => {
      state.attendanceNotes = [];
    });
    builder.addCase(getCourseTopicsList.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.courseTopics = action.payload;
      }
    });
    builder.addCase(getCourseTopicsList.rejected, (state, action) => {
      state.courseTopics = [];
    });

    builder.addCase(getSessionsList.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.sessions = action.payload;
        const obj = action.payload.find(
          (item) => item.currentAcademicSessionFlag === "Y"
        );
        state.sessionId = obj.sessionId;
      }
    });
    // builder.addCase(getCourseTopicSuggestionList.fulfilled, (state, action) => {
    //   if (action.payload.length > 0) {
    //     state.courseTopicSuggestions = action.payload;
    //   }
    // });
    builder.addCase(getTeacherSchedule.fulfilled, (state, action) => {
      if (action.payload?.length > 0) {
        state.schedules = prepareScheduleData(action.payload);
      }
    });
    builder.addCase(getTeacherSchedule.rejected, (state, action) => {
      state.schedules = [];
    });
  },
});

// Ээлжит хичээл
const getClassesByInstructorId = createAsyncThunk(
  "journal/getSessionList",
  async (params, { rejectWithValue }) => {
    const { personId, institutionId } = localStorage;
    return await apiGet(
      `classes/listByInstructorId/${personId}/${institutionId}`
    );
  }
);

// Улирал
const getSessionListByClassId = createAsyncThunk(
  "journal/getSessionListByClassId",
  async (params, { rejectWithValue }) => {
    return await apiGet(`classes/sessionListByClassId/${params.classId}`);
  }
);

// Ирц багшийн тэмдэглэл авах
const getAttendanceNoteList = createAsyncThunk(
  "journal/getAttendanceNoteList",
  async (params, { rejectWithValue }) => {
    const { institutionId, academicYear } = localStorage;
    const { classId, sessionId } = params;
    if (institutionId && classId && academicYear && sessionId) {
      return await apiGet(
        `attendances/class/${institutionId}/${classId}/${academicYear}/${sessionId}`
      );
    } else {
      rejectWithValue("");
    }
  }
);

// Нэгж хичээлийн сэдэв авах
const getCourseTopicsList = createAsyncThunk(
  "journal/getCourseTopicsList",
  async (params, { rejectWithValue }) => {
    const { teacherClass } = useJournal();
    return await apiGet(
      `course/topics/list/${teacherClass.institutionId}/${params.courseId}`
    );
  }
);

// // Багшийн өмнөх тэмдэглэлүүдийг авах
// const getCourseTopicSuggestionList = createAsyncThunk(
//   "journal/getCourseTopicSuggestionList",
//   async (params, { rejectWithValue }) => {
//     const { personId } = localStorage;
//     return await apiGet(
//       `course/courseTopics/suggestions/${params.courseTopicId}/${params.courseId}/${personId}`
//     );
//   }
// );

// Нэгж хичээлийн сэдэв авах
const getSessionsList = createAsyncThunk(
  "journal/getSessionsList",
  async (params, { rejectWithValue }) => {
    const { institutionId, academicYear } = localStorage;
    return await apiGet(`session/list/${institutionId}/${academicYear}`);
  }
);

// Ээлжит хичээл
const getTeacherSchedule = createAsyncThunk(
  "journal/getTeacherSchedule",
  async (params, { rejectWithValue }) => {
    const { personId, institutionId, academicYear } = localStorage;
    const startDate = moment(params.activeDate)
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    const endDate = moment(startDate).add(4, "days").format("YYYY-MM-DD");
    return await apiGet(
      `classMembers/weekday/${institutionId}/${personId}/${academicYear}/${startDate}/${endDate}`
    );
  }
);

export {
  getClassesByInstructorId,
  getSessionListByClassId,
  getAttendanceNoteList,
  getCourseTopicsList,
  // getCourseTopicSuggestionList,
  getSessionsList,
  getTeacherSchedule,
};
export const {
  changeTeacherClass,
  changeClassSessionId,
  changeSessionId,
  changeCourseTopics,
  changeCourseTopicsExercise,
  changeActiveDate,
} = journalSlice.actions;
export default journalSlice.reducer;
