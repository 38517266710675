import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PrimaryButton from "../../components/base/PrimaryButton/PrimaryButton";
// import { showLoader } from "../../components/base/Loader/loaderSlice";
import loader from "../../components/base/Loader/LoaderSlice";
import { apiGet, apiPost, call } from "../../utils/helper";
import Loader from "../../components/base/Loader/Loader";
import { hideLoader } from "../../components/base/Loader/LoaderSlice";
import { getInstitution } from "./testReduxPageSlice";
import useAppSlice from "../../hooks/useAppSlice";

export default function TestReduxPage() {
  // const [data, setData] = useState([]);
  const loading = useSelector((state) => state.loader.loading);
  const data = useSelector((state) => state.testReduxPage.data);
  const dispatch = useDispatch();

  // apiGet(`common/generalInfo/40099/2022-03-07`)
  //   .then((res) => setData(res))
  //   .catch((err) => setData([]));

  const fetchTest = () => {
    dispatch(getInstitution({ insId: "40099", date: "2022-03-07" }));

    // apiPost(`profile/stdntContactEmail`, {
    //   studentEmailId: "100001769052169",
    //   institutionIsd: 40130,
    //   personId: 90000003585740,
    //   emailType: "H1",
    //   emailTypeName: "Хувийн цахим шуудан",
    //   emailAddress: "test2@ymail.com",
    //   event: "ADD",
    //   emailId: 100001769052169,
    // }).then((res) => {
    //   console.log("post res", res);
    // });
    // call("get", `common/generalInfo/40099/2022-03-07`, {}, 10000).then((res) =>
    //   console.log("res1", res)
    // );
    // call("get", `common/generalInfo/40099/2022-03-07`, {}, 1000).then((res) =>
    //   console.log("res2", res)
    // );
  };

  const { getTeacherGroups, teacherGroups } = useAppSlice();

  return (
    <div>
      {`TestReduxPage: ${teacherGroups?.length}`} {loading && <Loader />}
      <PrimaryButton onClick={getTeacherGroups}>click to load</PrimaryButton>
      <PrimaryButton onClick={() => dispatch(hideLoader())}>
        click to hide loader
      </PrimaryButton>
    </div>
  );
}
