import { useDispatch, useSelector } from "react-redux";
import {
  getClassesByInstructorId,
  getSessionListByClassId,
  changeTeacherClass,
  changeClassSessionId,
  changeSessionId,
  getSessionsList,
} from "../pages/Journal/journalSlice";

const useJournal = () => {
  const dispatch = useDispatch();

  // Ээлжит хичээл
  const teacherClasses = useSelector((state) => state.journal.teacherClasses);
  const teacherClassId = useSelector((state) => state.journal.teacherClassId);
  const teacherClass = teacherClasses.find(
    (item) => item.classId === teacherClassId
  );
  const classSunmList = useSelector((state) => state.journal.classList);
  const classSessions = useSelector((state) => state.journal.classSessions);

  const classSessionId = useSelector((state) => state.journal.classSessionId);
  const classSession = classSessions.find(
    (item) => item.sessionId === classSessionId
  );
  const sessionId = useSelector((state) => state.journal.sessionId);
  const sessions = useSelector((state) => state.journal.sessions);

  const classSessionIdChanged = useSelector(
    (state) => state.journal.classSessionIdChanged
  );

  // const attendanceNotes = useSelector((state) => state.journal.attendanceNotes);

  // Ээлжит хичээлийг буцаана.
  const getTeacherClasses = () => dispatch(getClassesByInstructorId());

  const handleTeacherClass = (id) => dispatch(changeTeacherClass(id));

  const handleClassSessionId = (id) => dispatch(changeClassSessionId(id));

  const handleSessionId = (id) => dispatch(changeSessionId(id));

  const getSessionListByClass = (id) =>
    dispatch(getSessionListByClassId({ classId: id }));

  const getSessions = () => dispatch(getSessionsList());
  return {
    teacherClassId,
    teacherClasses,
    teacherClass,
    getTeacherClasses,
    classSessions,
    classSession,
    classSessionId,
    classSessionIdChanged,
    handleTeacherClass,
    handleClassSessionId,
    getSessionListByClass,
    sessionId,
    sessions,
    getSessions,
    handleSessionId,
    classSunmList,
  };
};

export default useJournal;
