import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#4F46BB",
    },
    secondary: {
      main: "#ff7f5c",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    background: {
      main: "#F6F9FC",
    },
    text: {
      primary: "#000",
      secondary: "#9A9A9A",
      system: "#4F46BB",
      blue: "#1E3A8A",
      info: "#1D4ED8",
      success: "#2e7d32",
      error: "#d32f2f",
      warning: "#ff7f5c",
    },
    warning: {
      main: "#ff7f5c",
      light: "#ff9800",
      dark: "#e65100",
    },
    info: {
      main: "#1D4ED8",
      light: "#03a9f4",
      dark: "#01579b",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
    },
  },
  shape: {
    borderRadius: 6,
  },
  spacing: 8,
  typography: {
    h1: {
      fontSize: "64px",
      lineHeight: "80px",
      fontWeight: 700,
      spacing: 2,
    },
    h2: {
      fontSize: "48px",
      lineHeight: "64px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "32px",
      lineHeight: "48px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "24px",
      lineHeight: "36px",
      fontWeight: 700,
    },

    h5: {
      fontSize: "20px",
      lineHeight: "30px",
      fontWeight: 700,
    },
    h6: {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 600,
    },

    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 400,
    },
    caption: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 400,
    },
    overline: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 700,
    },
    button: {
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 700,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "secondary" && {
              color: "#fff",
            }),
          ...(ownerState.size === "xs" && {
            fontSize: "12px",
            padding: "4px 8px",
          }),
        }),
      },
    },
  },
});
