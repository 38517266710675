import React from "react";
import styles from "./style.module.scss";
import loadingCircleSmall from "../../../assets/images/loadingCircleSmall.png";
import loadingCircleBig from "../../../assets/images/loadingCircleBig.png";
import loadingLogo from "../../../assets/images/loadingLogo.svg";

export default function Loader() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.loading}>
          <img src={loadingCircleBig} className={styles.loadingCircleBig} />
        </div>
        <div className={styles.loading1}>
          <img src={loadingCircleSmall} className={styles.loadingCircleSmall} />
        </div>
        <div className={styles.logo}>
          <img src={loadingLogo} unselectable="on" />
        </div>
      </div>
    </>
  );
}
