import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGet } from "../../../utils/helper";

const studentSlice = createSlice({
  name: "student",
  initialState: {
    // Бүлэг
    teacherGroup: [],
    teacherSelectedGroup: undefined,

    //Сурагч
    teacherStudent: [],
    teacherStudentMap: {},

    // Ээлжит хичээл
    teacherClasses: [],
    teacherClass: undefined,
  },
  reducers: {
    setSelectedG: {
      reducer: (state, action) => {
        state.teacherSelectedGroup = action.payload;
      },
      prepare: (group) => {
        return { payload: group };
      },
    },
    setStudent: {
      reducer: (state, action) => {
        state.teacherStudent = state.teacherStudentMap[action.payload];
      },
      prepare: (param) => {
        return { payload: param };
      },
    },
  },
  extraReducers: (builder) => {
    //GetGroupList
    builder.addCase(getGroupList.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        // state.teacherGroups = action.payload;
        state.teacherGroup = action.payload;
        state.teacherSelectedGroup = action.payload[0];
      }
    });
    builder.addCase(getGroupList.rejected, (state, action) => {
      state.teacherGroup = [];
      state.teacherSelectedGroup = undefined;
    });

    //GetStudentList
    builder.addCase(getStudentList.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.teacherStudent = action.payload;
        if (state.teacherSelectedGroup)
          state.teacherStudentMap[state.teacherSelectedGroup.studentGroupID] =
            action.payload;
      }
    });
    builder.addCase(getStudentList.rejected, (state, action) => {
      state.teacherStudent = [];
    });
  },
});

const getGroupList = createAsyncThunk(
  "student/getGroupList",
  async (params, { rejectWithValue }) => {
    const { personId, institutionId } = localStorage;
    return await apiGet(`teacher/groups/list/${personId}/${institutionId}`);
  }
);

const getStudentList = createAsyncThunk(
  "student/getStudentList",
  async (param, { rejectWithValue }) => {
    return await apiGet(`teacher/group/students/${param}`);
  }
);

export const { setSelectedG, setStudent } = studentSlice.actions;
export { getGroupList, getStudentList };
export default studentSlice.reducer;
