import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet } from "../../utils/helper";

const springWorkSlice = createSlice({
  name: "springWork",
  initialState: {
    // Ангиийн жагсаалт
    classesList: [],
    selectClass: "",
    //active tab
    activeTab: 0,
    dashboardData: [],
    termData: [],
    journalSummaryListData: [],
    termValue: "",
    lessonGradeSelect: "",
    lessonGradeData: [],
    actionStudentList: [],
    graduationCourseList: [],
    // Ангиийн жагсаалт төгсөлт
    classesListGrad: [],
    selectClassGrad: "",
    //active tab төгсөлт
    activeTabGrad: 0,
    dashboardDataGrad: [],
    journalSummaryListDataGrad: [],
    termValueGrad: "",
    lessonGradeDataGrad: [],
    lessonGradeSelectGrad: "",
    graduationCourseListGrad: [],
    subjectListGrad: [],
    //sub
    classesListSub: [],
    selectClassSub: " ",
  },
  reducers: {
    //sub select class change
    changeClassSub: {
      reducer: (state, action) => {
        state.selectClassSub = state.classesListSub.find(
          (el) => el.studentGroupID === action.payload
        );
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
    //төгсөлт
    changeClassGrad: {
      reducer: (state, action) => {
        state.selectClassGrad = state.classesListGrad.find(
          (el) => el.studentGroupId === action.payload
        );
        state.dashboardDataGrad = [];
        state.termDataGrad = [];
        state.lessonGradeDataGrad = [];
        state.graduationCourseListGrad = [];
        state.journalSummaryListDataGrad = [];
        state.subjectListGrad = [];
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
    changeTabGrad: {
      reducer: (state, action) => {
        state.activeTabGrad = action.payload;
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
    setDashboardDataGrad: {
      reducer: (state, action) => {
        state.dashboardDataGrad = action.payload;
      },
      prepare: (data) => {
        return {
          payload: data,
        };
      },
    },
    setJournalSummaryListDataGrad: {
      reducer: (state, action) => {
        state.journalSummaryListDataGrad = action.payload;
      },
      prepare: (data) => {
        return {
          payload: data,
        };
      },
    },
    setTermValueGrad: {
      reducer: (state, action) => {
        state.termValueGrad = action.payload;
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
    changeLessonGradeGrad: {
      reducer: (state, action) => {
        state.lessonGradeSelectGrad = action.payload;
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
    changeGraduationCourseListGrad: {
      reducer: (state, action) => {
        state.graduationCourseListGrad = action.payload;
      },
      prepare: (data) => {
        return {
          payload: data,
        };
      },
    },
    changeSubjectListGrad: {
      reducer: (state, action) => {
        state.subjectListGrad = action.payload;
      },
      prepare: (data) => {
        return {
          payload: data,
        };
      },
    },
    //дэвшилт

    changeClass: {
      reducer: (state, action) => {
        state.selectClass = state.classesList.find(
          (el) => el.studentGroupId === action.payload
        );

        state.dashboardData = [];
        state.termData = [];
        state.lessonGradeData = [];
        state.graduationCourseList = [];
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
    changeTab: {
      reducer: (state, action) => {
        state.activeTab = action.payload;
      },
      prepare: (id) => {
        console.log(id);
        return {
          payload: id,
        };
      },
    },
    setDashboardData: {
      reducer: (state, action) => {
        state.dashboardData = action.payload;
      },
      prepare: (data) => {
        return {
          payload: data,
        };
      },
    },
    setTermData: {
      reducer: (state, action) => {
        state.termValue = action.payload[0].termId;
        state.termValueGrad = action.payload[0].termId;
        state.termData = action.payload;
      },
      prepare: (data) => {
        return {
          payload: data,
        };
      },
    },
    setTermValue: {
      reducer: (state, action) => {
        state.termValue = action.payload;
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
    setJournalSummaryListData: {
      reducer: (state, action) => {
        state.journalSummaryListData = action.payload;
      },
      prepare: (data) => {
        return {
          payload: data,
        };
      },
    },
    changeLessonGrade: {
      reducer: (state, action) => {
        state.lessonGradeSelect = action.payload;
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
    changeActionStudentList: {
      reducer: (state, action) => {
        state.actionStudentList = [...action.payload];
      },
      prepare: (data) => {
        return {
          payload: data,
        };
      },
    },
    changeGraduationCourseList: {
      reducer: (state, action) => {
        state.graduationCourseList = action.payload;
      },
      prepare: (data) => {
        return {
          payload: data,
        };
      },
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getClassList.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.classesList = action.payload;
        state.selectClass = action.payload[0];
        // state.selectClassId = action.payload[0].programOfStudyId;
      }
    });
    builder.addCase(getClassListGrad.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.classesListGrad = action.payload;
        state.selectClassGrad = action.payload[0];
        // state.selectClassIdGrad = action.payload[0].programOfStudyId;
      }
    });
    builder.addCase(getLessonGradeSelect.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.lessonGradeData = action.payload;
        state.lessonGradeSelect =
          action.payload[action.payload.length - 1].programElementId;
      }
    });
    builder.addCase(getLessonGradeSelectGrad.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.lessonGradeDataGrad = action.payload;
        state.lessonGradeSelectGrad =
          action.payload[action.payload.length - 1].programElementId;
      }
    });
    builder.addCase(getClassListSub.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.classesListSub = action.payload;
        state.selectClassSub = action.payload[0];
      }
    });
  },
});

// Ангиийн жагсаалт
const getClassList = createAsyncThunk(
  "springWork/getClassList",
  async (params, { rejectWithValue }) => {
    const { personId, institutionId, academicYear } = localStorage;
    return await apiGet(
      `classes/groups/grad/progression/${institutionId}/${personId}/${academicYear}/n`
    );
  }
);
// Ангиийн жагсаалт төгсөлт
const getClassListGrad = createAsyncThunk(
  "springWork/getClassListGrad",
  async (params, { rejectWithValue }) => {
    const { personId, institutionId, academicYear } = localStorage;
    return await apiGet(
      `classes/groups/grad/progression/${institutionId}/${personId}/${academicYear}/y`
    );
  }
);
// Ангиийн жагсаалт цэцэрлэг
const getClassListSub = createAsyncThunk(
  "springWork/getClassListSub",
  async (params, { rejectWithValue }) => {
    const { personId, institutionId } = localStorage;
    return await apiGet(`sub/group/list/${personId}/${institutionId}`);
  }
);
const getLessonGradeSelect = createAsyncThunk(
  "springWork/getLessonGradeSelect",
  async (params, { rejectWithValue }) => {
    const { institutionId } = localStorage;
    return await apiGet(
      `progression/course/grade/${institutionId}/${params.programOfStudyId}/${params.programStageId}/${params.programPlanId}`
    );
  }
);

const getLessonGradeSelectGrad = createAsyncThunk(
  "springWork/getLessonGradeSelectGrad",
  async (params, { rejectWithValue }) => {
    const { institutionId } = localStorage;
    return await apiGet(
      `progression/course/grade/${institutionId}/${params.programOfStudyId}/${params.programStageId}/${params.programPlanId}`
    );
  }
);
export {
  getClassList,
  getLessonGradeSelect,
  getClassListGrad,
  getLessonGradeSelectGrad,
  getClassListSub,
};
export const {
  changeClassSub,
  changeClass,
  changeTab,
  setDashboardData,
  setTermData,
  setJournalSummaryListData,
  setTermValue,
  changeLessonGrade,
  changeActionStudentList,
  changeGraduationCourseList,
  changeTabGrad,
  changeClassGrad,
  setDashboardDataGrad,
  setJournalSummaryListDataGrad,
  setTermValueGrad,
  changeLessonGradeGrad,
  changeGraduationCourseListGrad,
  changeSubjectListGrad,
} = springWorkSlice.actions;
export default springWorkSlice.reducer;
