import { toastify } from "../components/base/Toastify/Toastify";

const handleError = (error) => {
  if (error.response) {
    const { status } = error.response;

    const errorMessage = error.response.data.RESPONSE_MESSAGE;

    switch (status) {
      case 302:
        toastify("ERROR", errorMessage);
        break;
      case 401:
        toastify("ERROR", errorMessage);
        localStorage.clear();
        clearListCookies();
        return (window.location.href = "/");
      case 403:
        toastify("ERROR", errorMessage);
        break;
      case 404:
        toastify("ERROR", errorMessage);
        break;
      case 500:
        if (error.response.data.RESPONSE_MESSAGE === "Unauthorized") {
          localStorage.clear();
          return (window.location.href = process.env.REACT_APP_HOME_URL);
        }
        if (error.response.data.SUCCESS_CODE === 401) {
          localStorage.clear();
          clearListCookies();
          toastify("ERROR", "Хандах эрх дууссан");
          return (window.location.href = process.env.REACT_APP_HOME_URL);
        }
        break;
      default:
        toastify("ERROR", errorMessage);
    }
  }
  return error;
};
function clearListCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var spcook = cookies[i].split("=");
    deleteCookie(spcook[0]);
  }
  function deleteCookie(cookiename) {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    var expires = ";expires=" + d;
    var name = cookiename;
    var value = "";
    document.cookie = name + "=" + value + expires + "; path=/";
  }
}
export default handleError;
