import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet } from "../../utils/helper";

const classSlice = createSlice({
  name: "classSlice",
  initialState: {
    groups: [],
    studentGroupId: "",
  },
  reducers: {
    changeStudentGroupId: {
      reducer: (state, action) => {
        state.studentGroupId = action.payload;
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroupsList.fulfilled, (state, action) => {
      //   console.log("getGroupsList.fulfilled", action.payload);
      if (action.payload.length > 0) {
        state.groups = action.payload;
        state.studentGroupId = action.payload[0]?.studentGroupID;
      }
    });
    builder.addCase(getGroupsList.rejected, (state, action) => {
      state.groups = [];
    });
  },
});

const getGroupsList = createAsyncThunk(
  "class/getGroupsList",
  async (params, { rejectWithValue }) => {
    const { institutionId, academicYear, personId } = localStorage;
    return await apiGet(
      `/groupattendance/group/list/${institutionId}/${personId}/${academicYear}`
    );
  }
);

export { getGroupsList };

export const { changeStudentGroupId } = classSlice.actions;
export default classSlice.reducer;
