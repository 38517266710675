import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGet } from "../../utils/helper";

const parentSlice = createSlice({
  name: "parent",
  initialState: {
    //Багшийн дааж авсан анги
    groups: [],
    group: null,
    studentGroupId: "",
    groupMembers: [],
    absenceCategory: [],
  },
  reducers: {
    changeStudentGroupid: {
      reducer: (state, action) => {
        state.studentGroupId = action.payload;
        if (state.groups.length) {
          state.group = state.groups.find(
            (item) => item.studentGroupId === action.payload
          );
        }
      },
      prepare: (id) => {
        return {
          payload: id,
        };
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClassesGroups.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.groups = action.payload;
        state.group = action.payload[0];
        state.studentGroupId = action.payload[0].studentGroupId;
      }
    });
    builder.addCase(getClassesGroups.rejected, (state, action) => {
      state.groups = [];
      state.group = null;
      state.studentGroupId = "";
    });
    builder.addCase(getGroupMembersList.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.groupMembers = action.payload;
      }
    });
    builder.addCase(getGroupMembersList.rejected, (state, action) => {
      state.groupMembers = [];
    });
    builder.addCase(getAbsenceCategoryList.fulfilled, (state, action) => {
      if (action.payload.length > 0) {
        state.absenceCategory = action.payload;
      }
    });
    builder.addCase(getAbsenceCategoryList.rejected, (state, action) => {
      state.absenceCategory = [];
    });
  },
});

//Багшийн дааж авсан ангиудыг авна
const getClassesGroups = createAsyncThunk(
  "parent/getClassesGroups",
  async (params, { rejectWithValue }) => {
    const { institutionId, personId, academicYear } = localStorage;
    //class/group/plans/${institutionId}/${personId}/${academicYear}
    //ene service ashiglah

    return await apiGet(
      `classes/groups/${institutionId}/${personId}/${academicYear}`
    );
  }
);

const getGroupMembersList = createAsyncThunk(
  "parent/getGroupMembers",
  async (params, { rejectWithValue }) => {
    return await apiGet(`group/members/${params.studentGroupId}`);
  }
);

const getAbsenceCategoryList = createAsyncThunk(
  "parent/getAbsenceCategory",
  async (params, { rejectWithValue }) => {
    return await apiGet(`common/lookup/ABSENCE_CATEGORY/2`);
  }
);

export { getClassesGroups, getGroupMembersList, getAbsenceCategoryList };

export const { changeStudentGroupid } = parentSlice.actions;
export default parentSlice.reducer;
