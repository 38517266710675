import { toastify } from "../components/base/Toastify/Toastify";
import axios from "axios";
import { API_URL } from "./config";
import handleError from "./handle-errors";
import { showLoader, hideLoader } from "../components/base/Loader/LoaderSlice";
import store from "../store/index";
import moment from "moment";
import { green, blue, teal, orange, indigo } from "@mui/material/colors";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();
axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: API_URL,
  cancelToken: source.token,
});

const getHeaders = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  Authorization: "Bearer " + localStorage.getItem("temp"),
});

const getPublicHeaders = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
});

// const config = {
//   disableToast: true,
//   disableLoader: true,
//   publicRequest: true,
// }

export const apiGet = async (url, config) => {
  const { temp } = localStorage;
  const disableLoader = config?.disableLoader ?? false;
  const disableToast = config?.disableToast ?? false;
  const publicRequest = config?.publicRequest ?? false;

  if (temp || publicRequest) {
    api.defaults.withCredentials = true;
    return new Promise((resolve, reject) => {
      if (!Boolean(disableLoader)) store.dispatch(showLoader());
      api
        .get(url, {
          headers: publicRequest ? getPublicHeaders() : getHeaders(),
        })
        .then((response) => {
          if (!Boolean(disableLoader)) store.dispatch(hideLoader());

          switch (response.data.SUCCESS_CODE) {
            case 200:
              resolve(response.data.RESULT);
              break;
            case 201:
              reject(response.data);
              // disableToast
              //   ? reject()
              //   : reject(toastify("WARNING", response.data.RESPONSE_MESSAGE));
              break;
            case 203:
              disableToast
                ? reject(response.data)
                : reject(toastify("WARNING", response.data.RESPONSE_MESSAGE));
              break;

            default:
              break;
          }
        })
        .catch((error) => {
          if (!Boolean(disableLoader)) store.dispatch(hideLoader());
          // console.log("aaaaaa: ", error.response);
          reject(handleError(error));
        });
    });
  } else {
    toastify("WARNING", "Хандах хугацаа дууссан.");
    logout();
  }
};

export const apiPost = async (url, data, config) => {
  const { temp } = localStorage;
  const disableLoader = config?.disableLoader ?? false;
  const disableToast = config?.disableToast ?? false;
  const publicRequest = config?.publicRequest ?? false;

  if (temp || publicRequest) {
    api.defaults.withCredentials = true;
    return new Promise((resolve, reject) => {
      store.dispatch(showLoader());
      api
        .post(url, data, {
          headers: publicRequest ? getPublicHeaders() : getHeaders(),
        })
        .then((response) => {
          if (!Boolean(disableLoader)) store.dispatch(hideLoader());

          switch (response.data.SUCCESS_CODE) {
            case 200:
              resolve(response.data);
              break;
            case 201:
              reject(toastify("WARNING", response.data.RESPONSE_MESSAGE));
              break;
            case 202:
              reject(toastify("WARNING", response.data.RESPONSE_MESSAGE));
              break;
            case 203:
              disableToast
                ? reject(response.data)
                : reject(toastify("WARNING", response.data.RESPONSE_MESSAGE));
              break;

            default:
              reject(handleError(response));
              break;
          }
        })
        .catch((error) => {
          if (!Boolean(disableLoader)) store.dispatch(hideLoader());
          reject(handleError(error));
        });
    });
  } else {
    toastify("WARNING", "Хандах хугацаа дууссан.");
    logout();
  }
};

const logout = () => {
  clearListCookies();
  localStorage.clear();
  toastify("WARNING", "Системээс гарлаа");
  window.location.href = process.env.REACT_APP_HOME_URL;
  window.reload();
};
function clearListCookies() {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var spcook = cookies[i].split("=");
    deleteCookie(spcook[0]);
  }
  function deleteCookie(cookiename) {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    var expires = ";expires=" + d;
    var name = cookiename;
    var value = "";
    document.cookie = name + "=" + value + expires + "; path=/acc/html";
  }
}

// const LoadingContext = createContext({
//   loading: false,
//   setLoading: null,
// });

export const call = async (method, url, data, timeout) => {
  axios.defaults.withCredentials = true; /**/

  // const dispatch = useDispatch();

  return new Promise((resolve, reject) => {
    let config = {};
    config["headers"] = getHeaders();
    config["baseURL"] = API_URL;
    config["cancelToken"] = source.token;
    config["method"] = method;
    config["url"] = url;
    if (data) {
      config["data"] = data;
    }

    // if (params) {
    //   config["params"] = params;
    // }

    store.dispatch(showLoader());
    setTimeout(() => {
      axios(config)
        .then((response) => {
          store.dispatch(hideLoader());
          if (response.data.SUCCESS_CODE === 200) {
            // console.log("response: ", response.data.RESULT);
            resolve(response.data);
          } else {
            reject(handleError(response));
          }
        })
        .catch((error) => {
          store.dispatch(hideLoader());
          reject(handleError(error));
        });
    }, timeout);
  });
};

export const treeList = (data, parent) => {
  var out = [];
  for (var i in data) {
    if (data[i].parentItemId === parent) {
      var submenu = treeList(data, data[i].itemId);
      let menuItem = null;
      if (submenu.length) {
        data[i].submenu = submenu;
        data[i].disableCheckbox = true;
        menuItem = {
          title: data[i].name,
          key: data[i].itemKey !== null ? data[i].itemKey : "",
          finish: data[i].visibleFlag === "Y",
          iconName: data[i].iconName,
          helpUrl: data[i].helpUrl,
          itemId: data[i].itemId,
          parentItemId: data[i].parentItemId,
          submenu: data[i].submenu,
        };
      } else {
        menuItem = {
          title: data[i].name,
          key: data[i].itemKey !== null ? data[i].itemKey : "",
          finish: data[i].visibleFlag === "Y",
          iconName: data[i].iconName,
          helpUrl: data[i].helpUrl,
        };
      }
      out.push(menuItem);
    }
  }
  return out;
};

export const getStudentDisplayName = (name, dateOfBirth) => {
  return `${name} /${moment(dateOfBirth).format("YYYY-MM-DD")}/`;
};

export const attBgColors = {
  // PRESENT: teal[50],
  // ONLINE: green[50],
  // EXCUSED: blue[50],
  // SICK: purple[50],
  // UNEXCUSED: red[50],
  PRESENT: green[500],
  ONLINE: teal[500],
  EXCUSED: blue[500],
  SICK: indigo[500],
  UNEXCUSED: orange[600],
};
export const attColors = {
  // PRESENT: teal[600],
  // ONLINE: green[600],
  // EXCUSED: blue[600],
  // SICK: purple[600],
  // UNEXCUSED: red[600],
  PRESENT: "#fff",
  ONLINE: "#fff",
  EXCUSED: "#fff",
  SICK: "#fff",
  UNEXCUSED: "#fff",
};

export const getLessonShortName = (longName) => {
  //Нийгэм судлал 7 (НСУ 07101) Заавал/Заавал/ => Нийгэм судлал 7 (заавал)
  const index = longName.indexOf("(");
  if (index > 0) {
    return `${longName.substring(0, index)} ${
      longName.toLowerCase().includes("заавал") ? " (заавал)" : ""
    }${longName.toLowerCase().includes("сонгон") ? " (сонгон)" : ""}`;
  }
  return longName;
};

export const gradeColors = {
  I: "#C0392B",
  II: "#FF7675",
  III: "#F39C12",
  IV: "#E5B458",
  V: "#74B9FF",
  VI: "#2980B9",
  VII: "#42CCA6",
  VIII: "#16A085",
};
