export const getCalendarDate = (date, time) => {
  let tmpDate = new Date(date);
  if (time.length > 2) {
    let str = time.split(":");
    let bDate = new Date(
      tmpDate.getFullYear(),
      tmpDate.getMonth(),
      tmpDate.getDate(),
      str[0],
      str[1]
    );
    return bDate;
  }
  return null;
};

export const prepareScheduleData = (schedules) => {
  const courseIds = schedules.map((el) => el.courseId);
  const uniqIds = [...new Set(courseIds)];
  const tmp = schedules.map((item, i) => {
    const start = getCalendarDate(item.classDate, item.beginHours).toString();
    const end = getCalendarDate(item.classDate, item.endHours).toString();
    if (start && end) {
      return {
        ...item,
        id: i,
        title: item.description,
        start,
        end,
        index: uniqIds.indexOf(item.courseId),
        scheduledFlag: item.scheduledFlag ?? "Y",
      };
    }
    return null;
  });

  return tmp;
};
